import React from "react";
import styles from "./WorkingContent.module.css";

const WorkingContent = () => {
  return (
    <div className={styles.workingContent}>
      <h2>
        How First Kerala <br />
        Works
      </h2>
      <p className={styles.info}>Business with a super-quick.</p>
      <p className={styles.desc}>
        First Kerala is the best platform in Kerala for buying and selling
        services and goods which operates a network of business traders. our
        mission is to change the way you trade by revealing the hidden value in
        everything near you.
      </p>
    </div>
  );
};

export default WorkingContent;
