import React from "react";

import styles from "./ShowcaseImageContent.module.css";

const ShowcaseImageContent = () => {
  return (
    <div className={styles.showcaseImageContent}>
      <div className={styles.showcaseImageContainer}>
        <img src="/Path1412.png" alt="background" className={styles.img1} />
        <div className={styles.halfCircleImage}>
          <img src="/halfCircle.png" alt="half-circle" />
        </div>
        <div className={styles.phone}>
          <div className={styles.logoText}>
            <img src="/logoText.png" alt="logoText" />
          </div>

          <div className={styles.phoneImg}>
            <img src="/rotateLogo.png" alt="phone" />
          </div>
        </div>

        <div className={styles.showcaseMessageLogo}>
          <img src="/message.png" alt="message-logo" />
        </div>
      </div>
    </div>
  );
};

export default ShowcaseImageContent;
