import React from "react";
import Showcase from "./components/showcase/Showcase";
import Working from "./components/working/Working";
import Footer from "../../components/Layout/footer/Footer";
import ReactFullpage from "@fullpage/react-fullpage";

const Homepage = () => (
  <ReactFullpage
    licenseKey="YOUR_VALID_LICENSE_KEY" // Replace YOUR_VALID_LICENSE_KEY with your actual key
    scrollingSpeed={1000}
    navigation={true}
    css3={true}
    scrollOverflow={true}
    navigationTooltips={["Showcase", "Working", "Footer"]}
    autoScrolling={true}
    slidesNavigation={true}
    controlArrows={false}
    fitToSection={true}
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <div className="section">
            <Showcase></Showcase>
          </div>
          <div className="section">
            <Working></Working>
          </div>
          <div className="section">
            <Footer></Footer>
          </div>
        </ReactFullpage.Wrapper>
      );
    }}
  />
);

export default Homepage;
