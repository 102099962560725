import React from "react";
import styles from "./WorkingImageContent.module.css";

const WorkingImageContent = () => {
  return (
    <div className={styles.workingImageContent}>
      <div className={styles.circle}>
        <img
          className={styles.semiCircle}
          src="/workingSemicircle.png"
          alt="semi-circle"
        />
        <img
          className={styles.message}
          src="/workingMessage.png"
          alt="message"
        />
        <img className={styles.phone} src="/workingPhone.png" alt="phone" />
      </div>
    </div>
  );
};

export default WorkingImageContent;
