import React from "react";
import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

const Footer = () => {
    return (
        <div className={styles.footer}>
            <img
                className={styles.footerPurple}
                src="/footerPurple.png"
                alt="purple semi circle"
            />
            <img
                className={styles.footerYellow}
                src="/footerYellow.png"
                alt="yellow semi circle"
            />
            <img
                className={styles.swiggle1}
                src="/footerSwiggle1.png"
                alt="swiggle 1"
            />
            <img
                className={styles.blueLine}
                src="/footerBlueLine.png"
                alt="blue line"
            />
            <img
                className={styles.greenLine}
                src="/footerGreen.png"
                alt="green line"
            />
            <img
                className={styles.footerGreenSwiggle}
                src="/footerGreenSwiggle.png"
                alt="green swiggle"
            />
            <div className={styles.footerContent}>
                <div className="row">
                    <div className="d-none d-lg-block col-lg-5 offset-lg-1">
                        <img
                            className={styles.footerPhoneImage}
                            src="/phoneAppTitle.png"
                            alt="phone"
                        />
                        <img
                            className={styles.footerImage}
                            src="/footerPhone.png"
                            alt="phone"
                        />
                    </div>
                    <div className={`col-12 col-lg-6 ${styles.footerInfo}`}>
                        <h2>
                            Join The Revolution
                            <br />
                            Download The App
                        </h2>

                        <p>
                            There are no ads, no affiliate marketers and no creepy tracking
                            Signal. So focus on sharing the moments.
                        </p>

                        <div className={styles.platforms}>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.fkshop.firstkerala&hl=en&gl=US"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <div className={styles.platform}>
                                    <span className={styles.platformLogo}>
                                        <img
                                            src="/playStoreLogo.png"
                                            alt="google play store logo"
                                        />
                                    </span>
                                    <div className={styles.platFormContent}>
                                        <span>GET IT ON</span>
                                        <span className={styles.name}>Google Play</span>
                                    </div>
                                </div>
                            </a>
                            <a
                                href="https://apps.apple.com/ca/app/first-kerala/id6469603048"
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <div className={styles.platform}>
                                    <span className={styles.platformLogo}>
                                        <img src="/appleLogo.png" alt="apple logo" />
                                    </span>
                                    <div className={styles.platFormContent}>
                                        <span>Available on the</span>
                                        <span className={styles.name}>App Store</span>
                                    </div>
                                </div>
                            </a>
                            {/* <a
                href="https://play.google.com/store/apps/details?id=com.fkshop.firstkerala&hl=en&gl=US"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className={styles.platform}>
                  <span className={styles.platformLogo}>
                    <img
                      src="/playStoreLogo.png"
                      alt="google play store logo"
                    />
                  </span>
                  <div className={styles.platFormContent}>
                    <span>First Kerala Android</span>
                    {/* <span className={styles.name}>Google Play</span> */}
                            {/*  </div>
                </div>
              </a> */}
                            {/* <a
                href="https://apps.apple.com/ca/app/first-kerala/id6469603048"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <div className={styles.platform}>
                  <span className={styles.platformLogo}>
                    <img
                      src="/playStoreLogo.png"
                      alt="google play store logo"
                    />
                  </span>
                  <div className={styles.platFormContent}>
                    <span>First Kerala IOS</span>
                    {/* <span className={styles.name}>Google Play</span> */}
                            {/* </div>
                </div>
              </a> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.footerLinks}>
                <div className="row">
                    <div className="col-lg-5">
                        <div className={styles.logoContainer}>
                            <img src="/footerLogo.png" alt="" />
                        </div>
                        {/* <p
              className={`${styles.footerLink} ${styles.extra} text-center text-lg-left`}
            >
              Data Ownership & Accessibility
              <br />
              Is A Question Of
              <br />
              High Importance For
            </p> */}
                    </div>

                    <div className="col-lg-3">
                        <Link
                            to="/contact"
                            className={`${styles.footerHeader} ${styles.small}`}
                            style={{
                                textDecoration: "none",
                                color: "#FFF",
                            }}
                        >
                            Contact
                        </Link>
                        <ul className={`${styles.footerLink}  text-center text-lg-left`}>
                            <li>First Kerala </li>
                            <li>First Floor</li>
                            <li>City Centre </li>
                            <li> Thalassery </li>
                            <li> Pin: 670101 </li>
                        </ul>
                    </div>
                    <div className="col-lg-3">
                        <Link
                            to="/privacy-and-policy"
                            className={`${styles.footerHeader} ${styles.small} `}
                            style={{ textDecoration: "none", color: "#FFF" }}
                        >
                            Policy
                        </Link>
                        <ul className={`${styles.footerLink} text-center text-lg-left`}>
                            <li>info@firstkerala.com</li>
                            <li>+91 7012364512</li>
                        </ul>
                    </div>
                    <div className="col-lg-1">
                        <Link
                            to="/terms"
                            className={`${styles.footerHeader} ${styles.small}`}
                            style={{ textDecoration: "none", color: "#FFF" }}
                        >
                            Terms
                        </Link>
                    </div>
                </div>
            </div>
            <div
                className={`d-flex justify-content-between align-items-center ${styles.footerSocials}`}
            >
                <div>
                    <p>All rights reserved. &copy;first kerala </p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
