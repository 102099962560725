import React from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./Navbar.module.css";
import { animateScroll as scroll } from "react-scroll";

// Navbar active link styling
const activeStyle = {
    borderBottom: "2px solid #fff",
};

const Navbar = () => {
    const toBottom = () => {
        scroll.scrollTo(550);
    };

    const toTop = () => {
        scroll.scrollToTop();
    };

    const toTerm = () => {
        scroll.scrollTo(80);
    };
    const toPolicy = () => {
        scroll.scrollTo(80);
    };

    return (
        <nav
            className={`navbar fixed-top navbar-expand-lg navbar-dark bg-dark ${styles.navbar}`}
        >
            <div className="container">
                <Link to="/" className="navbar-brand" onClick={() => toTop()}>
                    <img src="/logoNav.png" alt="Navbar-logo" className={styles.logo} />
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div id="navbarNav" className="collapse navbar-collapse">
                    <ul className="navbar-nav ml-auto">
                        <li className={`nav-item ${styles.navLink}`}>
                            <NavLink
                                className="nav-link"
                                activestyle={activeStyle}
                                to="/"
                            >
                                Home
                            </NavLink>
                        </li>
                        <li className={`nav-item ${styles.navLink}`}>
                            <NavLink
                                className="nav-link"
                                activestyle={activeStyle}
                                to="/terms"
                                onClick={() => toTerm()}
                            >
                                Terms
                            </NavLink>
                        </li>
                        <li className={`nav-item ${styles.navLink}`}>
                            <NavLink
                                className="nav-link"
                                activestyle={activeStyle}
                                to="/privacy-and-policy"
                                onClick={() => toPolicy()}
                            >
                                Policy
                            </NavLink>
                        </li>
                        <li className={`nav-item ${styles.navLink}`}>
                            <NavLink
                                className="nav-link"
                                activestyle={activeStyle}
                                to="/contact"
                                onClick={() => toBottom()}
                            >
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
