import React, { useEffect } from "react";
import styles from "./Policy.module.css";
import Navbar from "../../components/Layout/navbar/Navbar";
import Footer from "../../components/Layout/footer/Footer";

const PolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar></Navbar>
      <div className={styles.policy}>
        <div className={styles.design1}>
          <img src="/workingDesign1.png" alt="design" />
        </div>
        <div className="container">
          <div className={styles.header}>
            <h1>Privacy Policy</h1>
            <span className={styles.span1}></span>
            <span className={styles.span2}></span>
          </div>
          <div className={styles.content}>
            <p>
              This privacy notice for First Kerala ( "<span className={styles.textbold}>we,</span>"<span className={styles.textbold}>us,</span>"or"<span className={styles.textbold} >our</span>"), describes how and why we might collect, store, use, and/or share ( "<span className={styles.textbold}>process</span>") your information when you use our services ("<span className={styles.textbold} >Services</span>"), such as when you:
            </p>
            <ul className={styles.list} style={{ marginBottom: "0px" }}>
              <li className={styles.listItem}>
                <span className={styles.line}></span>
                <span>
                  Download and use our mobile application (First Kerala app) , or any other application of ours that links to this privacy notice
                </span>
              </li>
              <li className={styles.listItem}>
                <div className={styles.linePosition}>
                  <span className={styles.line}></span>
                </div>
                <span>
                  Engage with us in other related ways, including any sales, marketing, or events
                </span>
              </li>
            </ul>
            <p>
              <span className={styles.textbold}>Questions or concerns?</span> Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@firstkerala.com.
            </p>
            <div className={styles.content2}>
              <h2>SUMMARY OF KEY POINTS</h2>
              <p>
                <span className={styles.textbold}>
                  This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our <a className={styles.redirectid} href="#table">table of contents</a> below to find the section you are looking for.
                </span>
              </p>
              <p>
                <span className={styles.textbold}>What personal information do we process? </span>When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about <a className={styles.redirectid} href="#information"> personal information you disclose to us. </a>
              </p>
              <p>
                <span className={styles.textbold}> Do we process any sensitive personal information?</span> We do not process sensitive personal information.
              </p>
              <p>
                <span className={styles.textbold}> Do we receive any information from third parties?  </span>We do not receive any information from third parties.
              </p>
              <p>
                <span className={styles.textbold}>How do we process your information?</span> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about <a className={styles.redirectid} href="#process-info">
                  how we process your information.
                </a>
              </p>
              <p>
                <span className={styles.textbold}>In what situations and with which parties do we share personal information?</span> We may share information in specific situations and with specific third parties. Learn more about <a className={styles.redirectid} href="#share-personal">when and with whom we share your personal information.</a>
              </p>
              <p>
                <span className={styles.textbold}>How do we keep your information safe?</span> We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about, <a className={styles.redirectid} href="#infor-safe">how we keep your information safe.</a>
              </p>
              <p>
                <span className={styles.textbold}>What are your rights?</span> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about <a href="#privacy-right" className={styles.redirectid}>your privacy rights.</a>
              </p>
              <p>
                <span className={styles.textbold}>How do you exercise your rights? </span>The easiest way to exercise your rights is by submitting a <a className={styles.redirectid} href="https://app.termly.io/notify/e0870b4d-d876-49dc-acd7-b8e3f75a330d"> data subject access request</a> , or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
              </p>
              <p>
                Want to learn more about what we do with any information we collect? <a className={styles.redirectid} href="#table">Review the privacy notice in full.</a>
              </p>
              <div id="table" className={styles.content2}>
                <h2>TABLE OF CONTENTS</h2>
                <ul className={styles.list} style={{ marginBottom: "0px" }}>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#information" rel="noreferrer">WHAT INFORMATION DO WE COLLECT?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#process-info" rel="noreferrer">HOW DO WE PROCESS YOUR INFORMATION?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#legal-process" rel="noreferrer">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#share-personal" rel="noreferrer">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#tracking-tech" rel="noreferrer">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#social-login" rel="noreferrer">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#long-infor" rel="noreferrer">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#infor-safe" rel="noreferrer">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#collect-info" rel="noreferrer">DO WE COLLECT INFORMATION FROM MINORS?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#privacy-right" rel="noreferrer">WHAT ARE YOUR PRIVACY RIGHTS?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#control-features" rel="noreferrer">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#specific-privacy" rel="noreferrer">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#update-notice" rel="noreferrer">DO WE MAKE UPDATES TO THIS NOTICE?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#about-notice" rel="noreferrer">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                    </span>
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.line}></span>
                    <span>
                      <a href="#data-collect" rel="noreferrer">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div id="information" className={styles.content2}>
              <h2>WHAT INFORMATION DO WE COLLECT?</h2>
              <p>
                <span className={styles.textbold}>
                  Personal information you disclose to us
                </span>
              </p>
              <p><i>In Short:</i> We collect personal information that you provide to us.</p>
              <p>
                We collect personal information that you voluntarily provide to us when you register on the Services,  express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
              </p>
              <p>
                <span className={styles.textbold}>Personal Information Provided by You.</span> The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
              </p>
              <ul className={styles.list} style={{ marginBottom: "0px" }}>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    names
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    phone numbers
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    email addresses
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    mailing addresses
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    contact preferences
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    billing addresses
                  </span>
                </li>
              </ul>
              <p>
                <span className={styles.textbold}>Sensitive Information.</span>  We do not process sensitive information.
              </p>
              <p>
                <span className={styles.textbold}> Social Media Login Data.</span> We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will collect the information described in the section called " <a href="#social-login" className={styles.redirectid}> HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a> " below.
              </p>
              <p>
                <span className={styles.textbold}>Application Data.</span> If you use our application(s), we also may collect the following information if you choose to provide us with access or permission:
              </p>
              <ul className={styles.list} style={{ marginBottom: "0px" }}>
                <li className={styles.listItem}>
                  <span>
                    Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span>
                    Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's camera, storage, social media accounts, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span>
                    Mobile Device Data. We automatically collect device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span>
                    Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
                  </span>
                </li>
              </ul>
              <p>
                This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.
              </p>
              <p>
                All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
              </p>
              <p className={styles.textbold}>Information automatically collected</p>
              <p>
                <i>In Short: </i> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
              </p>
              <p>
                We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
              </p>
              <p>
                Like many businesses, we also collect information through cookies and similar technologies.
              </p>
              <p>
                The information we collect includes:
              </p>
              <p>
                Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).
              </p>
              <p>
                Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
              </p>
              <p>
                Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
              </p>
            </div>

            <div id="process-info" className={styles.content2}>
              <h2>HOW DO WE PROCESS YOUR INFORMATION?</h2>
              <p>
                <i>In Short:</i> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
              </p>
              <p className={styles.textbold}>
                We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
              </p>
              <ul className={styles.list} style={{ marginBottom: "0px" }}>
                <li className={styles.listItem}>
                  <span className={styles.bullet_point}></span>
                  <span>
                    <span className={styles.textbold} >To facilitate account creation and authentication and otherwise manage user accounts.</span> We may process your information so you can create and log in to your account, as well as keep your account in working order.
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.bullet_point}></span>
                  <span>
                    <span className={styles.textbold} >To request feedback.</span> We may process your information when necessary to request feedback and to contact you about your use of our Services
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.bullet_point}></span>
                  <span>
                    <span className={styles.textbold} >To send you marketing and promotional communications.</span> We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more information, see "<a className={styles.redirectid} href="#privacy-right"> WHAT ARE YOUR PRIVACY RIGHTS? </a>" below.
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.bullet_point}></span>
                  <span>
                    <span className={styles.textbold} >To protect our Services. </span>We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.bullet_point}></span>
                  <span>
                    <span className={styles.textbold} >To save or protect an individual's vital interest. </span>We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
                  </span>
                </li>
              </ul>
            </div>

            <div id="legal-process" className={styles.content2}>
              <h2>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
              <p>
                <i>In Short: </i>
                We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. , legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
              </p>
              <p className={styles.textbold}>
                If you are located in the EU or UK, this section applies to you.
              </p>
              <p>
                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
              </p>
              <p>
                <span className={styles.textbold}>Consent.</span> We may process your information if you have given us permission (i.e. , consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about <a href="#withdrawing" className={styles.redirectid}> withdrawing your consent.</a>
              </p>
              <p>
                <span className={styles.textbold}>Legitimate Interests.</span>We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
              </p>
              <ul className={styles.list} style={{ marginBottom: "0px" }}>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    Send users information about special offers and discounts on our products and services
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    Diagnose problems and/or prevent fraudulent activities
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    Understand how our users use our products and services so we can improve user experience
                  </span>
                </li>
              </ul>
              <p>
                <span className={styles.textbold}>Legal Obligations.</span> We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
              </p>
              <p>
                <span className={styles.textbold}>Vital Interests.</span> We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
              </p>
              <i className={styles.textbold}>
                If you are located in Canada, this section applies to you
              </i>
              <p>
                We may process your information if you have given us specific permission (i.e. , express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. , implied consent). You can  <a className={styles.redirectid} href="#withdrawing"> withdraw your consent </a> at any time.
              </p>
              <p>
                In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
              </p>
              <ul className={styles.list} style={{ marginBottom: "0px" }}>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    For investigations and fraud detection and prevention
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    For business transactions provided certain conditions are met
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    For identifying injured, ill, or deceased persons and communicating with next of kin
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    If the collection is solely for journalistic, artistic, or literary purposes
                  </span>
                </li>
                <li className={styles.listItem}>
                  <div className={styles.linePosition}>
                    <span className={styles.line}></span>
                  </div>
                  <span>
                    If the information is publicly available and is specified by the regulations
                  </span>
                </li>
              </ul>
            </div>

            <div id="share-personal" className={styles.content2}>
              <h2>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
              <p>
                <i>In Short:</i> We may share information in specific situations described in this section and/or with the following third parties.
              </p>
              <p>
                We may need to share your personal information in the following situations:
              </p>
              <p>
                <span className={styles.textbold}>Business Transfers.</span> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
              </p>
              <p>
                <span className={styles.textbold}> When we use Google Maps Platform APIs.</span> We may share your information with certain Google Maps Platform APIs (e.g. , Google Maps API, Places API). We use certain Google Maps Platform APIs to retrieve certain information when you make location-specific requests. This includes: and other similar information. A full list of what we use information for can be found in this section and in the previous section titled " <a className={styles.redirectid} href="#process-info">HOW DO WE PROCESS YOUR INFORMATION? </a>" We obtain and store on your device ( "cache" ) your location . You may revoke your consent anytime by contacting us at the contact details provided at the end of this document. The Google Maps Platform APIs that we use store and access cookies and other information on your devices. If you are a user currently in the European Economic Area (EU countries, Iceland, Liechtenstein, and Norway) or the United Kingdom, please take a look at our Cookie Notice .
              </p>
              <p>
                <span className={styles.textbold}>Business Partners. </span>We may share your information with our business partners to offer you certain products, services, or promotions.
              </p>
            </div>

            <div id="tracking-tech" className={styles.content2}>
              <h2>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
              <p>
                <i>In Short:</i> We may use cookies and other tracking technologies to collect and store your information.
              </p>
              <p>
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice .
              </p>
            </div>

            <div id="social-login" className={styles.content2}>
              <h2>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
              <p>
                <i>In Short:</i> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.
              </p>
              <p>
                Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.
              </p>
              <p>
                We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
              </p>
            </div>
            <div id="long-infor" className={styles.content2}>
              <h2>HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
              <p>
                <i>In Short:</i> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
              </p>
              <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than   the period of time in which users have an account with us .
              </p>
              <p>
                When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
              </p>
            </div>

            <div id="infor-safe" className={styles.content2}>
              <h2>HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
              <p>
                <i>In Short:</i> We aim to protect your personal information through a system of organizational and technical security measures.
              </p>
              <p>
                We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
              </p>
            </div>

            <div id="collect-info" className={styles.content2}>
              <h2>DO WE COLLECT INFORMATION FROM MINORS?</h2>
              <p>
                <i>In Short:</i> We do not knowingly collect data from or market to children under 18 years of age .
              </p>
              <p>
                We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at info@firstkerala.com .
              </p>
            </div>

            <div id="privacy-right" className={styles.content2}>
              <h2>WHAT ARE YOUR PRIVACY RIGHTS?</h2>
              <p>
                <i>In Short:</i>  In some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada , you have rights that allow you greater access to and control over your personal information.  You may review, change, or terminate your account at any time.
              </p>
              <p>
                In some regions (like the EEA, UK, Switzerland, and Canada ), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section "<a className={styles.redirectid} href="#update-notice"> HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </a>" below.
              </p>
              <p>
                We will consider and act upon any request in accordance with applicable data protection laws.
              </p>
              <p>
                If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your <a href="https://ec.europa.eu/newsroom/article29/items/612080" target="_blanck" className={styles.redirectid}>Member State data protection authority</a> or <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" target="_blanck" className={styles.redirectid}> UK data protection authority.</a>
              </p>
              <p>
                If you are located in Switzerland, you may contact the <a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blanck" className={styles.redirectid}>Federal Data Protection and Information Commissioner.</a>
              </p>
              <p id="withdrawing">
                <span className={styles.textbold} style={{ textDecoration: "underline" }}>Withdrawing your consent:</span> If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section " <a href="#update-notice" className={styles.redirectid}>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a> " below .
              </p>
              <p>
                However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
              </p>
              <p className={styles.textbold}>Account Information</p>
              <p>
                If you would at any time like to review or change the information in your account or terminate your account, you can:
              </p>
              <ul className={styles.list} style={{ marginBottom: "0px" }}>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>Log in to your account settings and update your user account.</span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>Log in to your account and go to profile and find terms and conditions tab and delete the acount over there.</span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>Contact us using the contact information provided.</span>
                </li>
              </ul>
              <p>
                Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
              </p>
              <p>
                <span style={{ textDecoration: "underline" }} className={styles.textbold}>Cookies and similar technologies:</span> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
              </p>
              <p>
                If you have questions or comments about your privacy rights, you may email us at info@firstkerala.com .
              </p>
            </div>

            <div id="control-features" className={styles.content2}>
              <h2>CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
              <p>
                Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ( "DNT" ) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized . As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
              </p>
            </div>

            <div id="specific-privacy" className={styles.content2}>
              <h2>DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
              <p>
                <i>In Short:</i> If you are a resident of , you are granted specific rights regarding access to your personal information.
              </p>
              <p className={styles.textbold}>
                What categories of personal information do we collect?
              </p>
              <p>
                We have collected the following categories of personal information in the past twelve (12) months:
              </p>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Category</th>
                    <th scope="col">Examples</th>
                    <th scope="col">Collected</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="col">A. Identifiers</th>
                    <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th scope="col">B . Protected classification characteristics under state or federal law</th>
                    <td>Gender and date of birth</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th scope="col">C . Commercial information</th>
                    <td>Transaction information, purchase history, financial details, and payment information</td>
                    <td >No</td>
                  </tr>
                  <tr>
                    <th scope="col">D . Biometric information</th>
                    <td>Fingerprints and voiceprints</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th scope="col">E . Internet or other similar network activity</th>
                    <td>Browsing history, search history, online behavior , interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th scope="col">F . Geolocation data</th>
                    <td>Device location</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th scope="col">G . Audio, electronic, visual, thermal, olfactory, or similar information</th>
                    <td>Images and audio, video or call recordings created in connection with our business activities</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th scope="col">H . Professional or employment-related information</th>
                    <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th scope="col">I . Education Information</th>
                    <td>Student records and directory information</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th scope="col">J . Inferences drawn from collected personal information</th>
                    <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
                    <td>No</td>
                  </tr>
                  <tr>
                    <th colSpan="2">K . Sensitive personal Information</th>
                    <td>No</td>
                  </tr>
                </tbody>
              </table>
              <p>
                We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
              </p>
              <ul className={styles.list} style={{ marginBottom: "0px" }}>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    Receiving help through our customer support channels;
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    Participation in customer surveys or contests; and
                  </span>
                </li>
                <li className={styles.listItem}>
                  <span className={styles.line}></span>
                  <span>
                    Facilitation in the delivery of our Services and to respond to your inquiries.
                  </span>
                </li>
              </ul>
              <p className={styles.textbold}>
                How do we use and share your personal information?
              </p>
              <p>
                Learn about how we use your personal information in the section, " <a href="#process-info" className={styles.redirectid}>HOW DO WE PROCESS YOUR INFORMATION? </a>"
              </p>
              <p className={styles.textbold}>
                Will your information be shared with anyone else?
              </p>
              <p>
                We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, " <a href="#share-personal" className={styles.redirectid}>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a> "
              </p>
              <p>
                We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information.
              </p>
              <p>
                We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
              </p>
            </div>

            <div id="update-notice" className={styles.content2}>
              <h2>DO WE MAKE UPDATES TO THIS NOTICE?</h2>
              <p>
                <i>In Short:</i> Yes, we will update this notice as necessary to stay compliant with relevant laws.
              </p>
              <p>
                We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
              </p>
            </div>

            <div id="about-notice" className={styles.content2}>
              <h2>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
              <p>
                If you have questions or comments about this notice, you may email us at <a className={styles.redirectid} href="mailto:info@firstkerala.com">info@firstkerala.com</a> or  contact us by post at:
              </p>
              <p className={styles.textbold}>
                First Kerala
                <br></br>
                First Kerala
                <br></br>
                First Floor ,City Centre
                <br></br>
                Thalassery , Kerala 670101
                <br></br>
                India
              </p>
            </div>

            <div id="data-collect" className={styles.content2}>
              <h2>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU</h2>
              <p>
                You have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact us at <a href="mailto:info@firstkerala.com">info@firstkerala.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
};

export default PolicyPage;
