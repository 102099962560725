import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from "./pages/homepage/Homepage";
import PolicyPage from "./pages/policy/Policy";
import TermsPage from "./pages/terms/Terms";
import Contact from "./pages/contact/Contact";
import React from "react";

const App = (() => {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/Home' element={<Homepage />} />
          <Route path="/privacy-and-policy" element={<PolicyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Router>
    </>
  )
});

export default App;