import React, { useEffect, useRef } from "react";
import Footer from "../../components/Layout/footer/Footer";
import Navbar from "../../components/Layout/navbar/Navbar";
const Contact = () => {
  const footerRef = useRef();
  useEffect(() => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, []);
  return (
    <div ref={footerRef}>
      <Navbar></Navbar>
      <Footer></Footer>
    </div>
  );
};
export default Contact;
