import React from "react";
import styles from "./Working.module.css";
import WorkingContent from "./WorkingContent";
import WorkingImageContent from "./WorkingImageContent";

const Working = () => {
  return (
    <div className={styles.working}>
      <div className={styles.design1}>
        <img src="/workingDesign2.png" alt="design" />
      </div>
      <div className="container">
        <div className="row">
          <div className="d-none d-lg-block col-lg-6">
            <WorkingImageContent></WorkingImageContent>
          </div>
          <div className="col-12 col-lg-6">
            <WorkingContent />
          </div>
        </div>
      </div>
      <div className={styles.design2}>
        <img src="/workingDesign1.png" alt="design" />
      </div>
      <img
        className={styles.swiggle1}
        src="/workingLine1.png"
        alt="swiggly line 1"
      />
      <img
        className={styles.swiggle2}
        src="/workingLine2.png"
        alt="swiggly line 2"
      />
    </div>
  );
};

export default Working;
