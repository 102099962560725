import React, { useEffect } from "react";
import styles from "./ShowcaseContent.module.css";
import anime from "animejs/lib/anime.es.js";
import "./letter.css";

const ShowcaseContent = () => {
  useEffect(() => {
    let textWrapper = document.querySelector(".ml10 .letters-c");
    let txt = textWrapper.innerText
      .split(" ")
      .reduce(
        (acc, text) =>
          acc +
          `<span class='d-inline-block'><span class="d-flex mr-3">${text
            .split("")
            .reduce(
              (acc, t) => acc + `<span class='letter'>${t}</span>`,
              ""
            )}</span></span>`,
        ""
      );
    textWrapper.innerHTML = txt;
    anime
      .timeline({ loop: true })
      .add({
        targets: ".ml10 .letter",
        rotateY: [-90, 0],
        duration: 1300,
        delay: (el, i) => 45 * i,
      })
      .add({
        targets: ".ml10",
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000,
      });
  }, []);
  return (
    <div className={styles.showcaseContent}>
      <h1 className={`ml10 ${styles.showcaseContentTitle}`}>
        <span className={`text-wrapper ${styles.text}`}>
          <span className="letters-c">Shopping Made Easy</span>
        </span>
      </h1>

      <p>Direct shop app connecting business owners with customers.</p>
      {/* <Link
        to={{
          pathname:
            "https://play.google.com/store/apps/details?id=com.fkshop.firstkerala",
        }}
        target="_blank"
        style={{ textDecoration: "none" }}
        rel="noreferrer"
      >
        <button
          className={styles.btn}
          // onClick={(e) =>
          //   (window.location.href =
          //     "")
          // }
        >
          <span>Get The First Kerala Shop </span>
          <span className={styles.btnIcon}>
            <i className="fas fa-long-arrow-alt-right"></i>
          </span>
        </button>
      </Link> */}

      <div className={styles.platforms}>
        <a
          href="https://play.google.com/store/apps/details?id=com.fkshop.firstkerala&hl=en&gl=US"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div className={styles.platform}>
            <span className={styles.platformLogo}>
              <img src="/playStoreLogo.png" alt="google play store logo" />
            </span>
            <div className={styles.platFormContent}>
              <span>GET IT ON</span>
              <span className={styles.name}>Google Play</span>
            </div>
          </div>
        </a>
        <a
          href="https://apps.apple.com/ca/app/first-kerala/id6469603048"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div className={styles.platform}>
            <span className={styles.platformLogo}>
              <img src="/appleLogo.png" alt="apple logo" />
            </span>
            <div className={styles.platFormContent}>
              <span>Available on the</span>
              <span className={styles.name}>App Store</span>
            </div>
          </div>
        </a>
        {/* <a
          href="https://apps.apple.com/ca/app/first-kerala/id6469603048"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <div className={styles.platform}>
            <span className={styles.platformLogo}>
              <img src="/playStoreLogo.png" alt="google play store logo" />
            </span>
            <div className={styles.platFormContent}>
              <span>First Kerala IOS</span>
            
            </div>
          </div>
        </a> */}
      </div>
    </div>
  );
};

export default ShowcaseContent;
