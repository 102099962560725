import React from "react";
import styles from "./Showcase.module.css";
import ShowcaseContent from "./ShowcaseContent";
import ShowcaseImageContent from "./ShowcaseImageContent";
import Navbar from "../../../../components/Layout/navbar/Navbar";

const Showcase = () => {
  return (
    <div className={styles.showcase}>
     <Navbar></Navbar>
      <div className={`container ${styles.showcaseContainer}`}>
      
        <div className="row">
          <div className="col-12 col-lg-6">
            <ShowcaseContent></ShowcaseContent>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <ShowcaseImageContent></ShowcaseImageContent>
      </div>
    </div>
  );
};

export default Showcase;
